import React, { useCallback, useState } from 'react'
import Img from 'gatsby-image'
import { Card } from 'rebass'
import { transparentize } from 'polished'

const ProjectCard = props => {
  // Computing prominent color of image.
  const prominentColor = props.colors.vibrant
  const backgroundColor = transparentize(0.85, prominentColor)
  const [isHover, setIsHover] = useState(false)

  const handleHover = useCallback(() => {
    setIsHover(value => !value)
  }, [])

  return (
    <Card
      {...props}
      bg={backgroundColor}
      borderRadius={10}
      css={{
        position: 'relative',
        cursor: 'pointer',
        transition: 'all 0.2s ease',

        '&:after': {
          content: '""',
          position: 'absolute',
          zIndex: -1,
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          borderRadius: 10,

          opacity: isHover ? 0.25 : 0,
          boxShadow: `
            0 64px 64px 0 ${prominentColor},
            0 32px 32px 0 ${prominentColor},
            0 16px 16px 0 ${prominentColor},
            0 8px 8px 0 ${prominentColor},
            0 4px 4px 0 ${prominentColor}
          `,

          transition: 'opacity 0.2s ease-in-out',
        },

        '&:hover': {
          zIndex: 2,
          transform: 'scale(1.05)',
        },
      }}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      <Img
        fluid={props.image}
        alt={props.alt}
        css={{
          overflow: 'hidden',
          borderRadius: 10,
          objectFit: 'cover',
          position: 'relative',
        }}
      />
    </Card>
  )
}

export default React.memo(ProjectCard)
