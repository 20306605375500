import * as React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import ProjectsGrid from './projects-grid'

export const ProjectsQuery = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                featuredimage {
                  publicURL
                  colors {
                    ...GatsbyImageColors
                  }
                  childImageSharp {
                    fluid(maxWidth: 200) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ allMarkdownRemark: { edges: nodes } }) => {
      const projects = nodes.map(({ node }) => ({
        name: node.frontmatter.title,
        slug: node.fields.slug,
        colors: node.frontmatter.featuredimage.colors,
        thumbnail: node.frontmatter.featuredimage.childImageSharp.fluid,
        path: node.frontmatter.featuredimage.publicURL,
      }))

      return <ProjectsGrid projects={projects} />
    }}
  />
)
