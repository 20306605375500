import React from 'react'
import { ProjectsQuery } from '../components/projects-query'
import { Head } from '../components/head'

const Index = () => (
  <>
    <Head title="L'Étrange Fabrique de Gigi" />
    <ProjectsQuery />
  </>
)

export default React.memo(Index)
