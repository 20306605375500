import * as React from 'react'
import Masonry from 'react-masonry-component'
import { Box } from 'rebass'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import ProjectCard from './project-card'
import './projects-grid.css'

const GUTTER_SIZE = [4, 8, 16]
const COLUMN_SIZE = [1, 1, 1, 1, 1 / 3, 1 / 3, 1 / 3, 1 / 3, 1 / 4]

const ProjectsGrid = ({ projects }) => (
  <Box
    mx={GUTTER_SIZE.map(gutter => -gutter)}
    my={GUTTER_SIZE.map(gutter => -gutter)}
  >
    <Masonry
      elementType={'ul'}
      style={{
        padding: 0,
        listStyle: 'none',
      }}
    >
      {projects.map(project => (
        <Box
          as="li"
          key={project.slug}
          width={COLUMN_SIZE}
          px={GUTTER_SIZE}
          my={GUTTER_SIZE}
        >
          <AniLink paintDrip hex="#f6dcb3" to={`/projets${project.slug}`}>
            <ProjectCard
              key={project.name}
              name={project.name}
              image={project.thumbnail}
              colors={project.colors}
              alt={project.name}
              path={project.path}
            />
          </AniLink>
        </Box>
      ))}
    </Masonry>
  </Box>
)

export default React.memo(ProjectsGrid)
